import React from "react";
import loadable from "@loadable/component";
import { discoveryFAQList } from "../components/DiscoveryAndPlanning/components/FAQSection.js/FAQSection";

const Layout = loadable(() => import("@common/Layout/Layout"));
const DiscoveryAndPlanning = loadable(() =>
    import("@components/DiscoveryAndPlanning/DiscoveryAndPlanning")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const DiscoveryAndPlanningPage = ({ path }) => (
    <>
        <SEO
            title="Product Discovery Phase for Startups and Tech"
            titleTemplate="Impressit"
            description="Efficient and high-quality project discovery phase and planning services for software development — an essential step for your project's future success!"
            path={path}
            faqSchema={discoveryFAQList}
        />
        <Layout path={path}>
            <DiscoveryAndPlanning />
        </Layout>
    </>
);

export default DiscoveryAndPlanningPage;
